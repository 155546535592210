<template>
    <svg class="svg-icon" viewBox="0 0 20 20">
		<path fill="none" d="M9.896,3.838L0.792,1.562v14.794l9.104,2.276L19,16.356V1.562L9.896,3.838z M9.327,17.332L1.93,15.219V3.27
		 l7.397,1.585V17.332z M17.862,15.219l-7.397,2.113V4.855l7.397-1.585V15.219z"></path>
	</svg>
</template>

<script>
export default {
   name: 'SeriesSVG'
}
</script>

<style>

</style>